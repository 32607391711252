import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';

import get from 'lodash/get';
import { Button, DownloaderWrapper } from './styles';

export const Downloader: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "assets" }, name: { regex: "/Resume-AditiAnomitaMohanty/" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `);

  const file = get(data, ['allFile', 'edges', '0', 'node', 'publicURL']);
  return (
    <DownloaderWrapper>
      <hr />
      <Button onClick={() => navigate('/')} className='go-back' data-ga={true} data-ga-label='back'>
        ‹ Back
      </Button>
      <Button
        title='Download as PDF'
        href={file}
        download
        className='download-pdf'
        data-ga={true}
        data-ga-label='download pdf'
      >
        Download PDF
        <svg
          viewBox='0 0 24 24'
          width='20'
          height='20'
          stroke='currentColor'
          strokeWidth='2'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='css-i6dzq1'
          data-ga={true}
          data-ga-label='download pdf'
        >
          <path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'></path>
          <polyline points='7 10 12 15 17 10'></polyline>
          <line x1='12' y1='15' x2='12' y2='3'></line>
        </svg>
      </Button>
    </DownloaderWrapper>
  );
};
