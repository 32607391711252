import React from 'react';
import { resume } from '@data/static';
import { SectionTitle, Paragraph } from '@views/resume/styles';

export const ProfessionalSummary: React.FC = () => {
  return (
    <section>
      <SectionTitle mt={0}>Summary</SectionTitle>
      <Paragraph>{resume.basics.summary}</Paragraph>
    </section>
  );
};
