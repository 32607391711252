import styled, { createGlobalStyle } from 'styled-components';
import { darken, transparentize } from 'polished';
import { margin, MarginProps } from 'styled-system';

export const ResumeGlobals = createGlobalStyle`
  /* Generated via https://www.gridlover.net/ */
  html {
    font-size: 18px;
    line-height: 30px;
  }
  body {
    font-family: ${(props) => props.theme.fonts.body};
    font-size: 1rem;
    line-height: 1.7;
    margin: 0;

    @media screen {
      background-color: #313944;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${(props) => props.theme.fonts.heading};
    font-weight: 700;
  }
  h1, .h1 {
    font-size: 3.3888889rem;
    line-height: 5rem;
    margin-top: 1.6666667rem;
    margin-bottom: 3.3333334rem;
    border-collapse: collapse;
  }
  h2, .h2 {
    font-size: 2.2777778rem;
    line-height: 3.3333333rem;
    margin-top: 1.6666667rem;
    margin-bottom: 1.6666667rem;
  }
  h3, .h3 {
    font-size: 1.5rem;
    line-height: 1.6666667rem;
    margin-top: 1.6666667rem;
    margin-bottom: 0rem;
  }
  h4, .h4 {
    font-size: 1rem;
    line-height: 1.6666667rem;
    margin-top: 1.6666667rem;
    margin-bottom: 0rem;
  }
  h5, .h5 {
    font-size: 1rem;
    line-height: 1.6666667rem;
    margin-top: 1.6666667rem;
    margin-bottom: 0rem;
  }
  p, ul, ol, pre, table, blockquote {
    margin-top: 0rem;
    margin-bottom: 1.6666667rem;
  }
  ul ul, ol ol, ul ol, ol ul {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  /* Let's make sure all's aligned */
  hr, .hr {
    border: 1px solid;
    margin: -1px 0;
  }
  a, b, i, strong, em, small, code {
    line-height: 0;
  }
  sub, sup {
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }

  // custom styles
  * {
    box-sizing: border-box;
  }

  a,
  a:visited {
    font-weight: 600;
    color: #1976d2;
    text-decoration: none;
    transition: color 0.3s ease-out;
  }

  a.subtle,
  a.subtle:visited {
    font-weight: inherit;
    color: inherit;
  }

  a:hover {
    color: ${darken(0.15, '#1976d2')};
  }

  @media print {
  .media-hide-print {
      display: none !important;
    }
  }

  @media screen {
  .media-hide-screen {
      display: none !important;
    }
  }

  hr {
    border-color: #afccf4;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const Paper = styled.main`
  background: #ffffff;
  color: ${(props) => props.theme.colors.text};
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: nowrap;

  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    margin: 2.5rem auto;
  }

  @media screen {
    border-radius: 2px;
    max-width: 50rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    min-height: 1200px;
  }

  @media screen and (max-width: 39.99em) {
    flex-direction: column;
    flex-wrap: wrap;
  }

  #section--work {
    @media print {
      margin-top: 1rem;
    }
  }

  @media print {
    #experience-timeline--sabre {
      page-break-before: always;
      margin-top: 1rem;
    }
  }
`;

export const Section = styled.section<{ secondary?: boolean }>`
  align-self: stretch;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
  -webkit-print-color-adjust: exact;
  background-color: ${({ secondary }) => (secondary ? '#E4EEFC' : '#FFFFFF')};

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    width: ${({ secondary }) => ((secondary ? 4 : 8) / 12) * 100}%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  @media print {
    ${({ secondary }) => (secondary ? `max-width: 300px;` : null)}
    height: 200vh;
  }
`;

Section.defaultProps = { secondary: false };

export const Title = styled.h1`
  line-height: 1.22;
  font-size: 2rem;
  margin: 0;
  color: ${(props) => props.theme.colors.heading};
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    margin-bottom: 1.77rem;
    max-width: 15rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 1.33rem;
  color: #4b74ac;
`;

export const SectionTitle = styled.h2<MarginProps>`
  position: relative;
  font-size: 1.333rem;
  line-height: 1.22;
  color: ${(props) => props.theme.colors.heading};
  z-index: 1;
  margin-bottom: 1.11rem;
  margin-top: -0.7rem;
  display: inline-block;

  &:after {
    content: ' ';
    display: inline-block;
    background-color: ${transparentize(0.75, '#4A8EEB')};
    width: 100%;
    height: 10px;
    position: absolute;
    top: 18px;
    left: 22px;
    z-index: -1;
  }

  ${margin}
`;

export const Paragraph = styled.p`
  font-size: 0.83rem;
`;

export const ReferenceList = styled.ul`
  font-size: 0.83rem;
  list-style-position: outside;
  padding-left: 1.15rem;
`;

export const HeroHeader = styled.header`
  @media screen and (max-width: 39.99em) {
    #avatar {
      display: none;
    }
  }
`;
