import React from 'react';
import { ContactList } from '@views/resume/contact/styles';
import { Image } from 'rebass/styled-components';

// data
import { me } from '@data/static';
import { contact, SHOW_CONTACT } from '@data/private';

// icons
import IconEmail from '@images/resume/icons/email.png';
import IconPhone from '@images/resume/icons/phone.png';
import IconGithub from '@images/resume/icons/github.png';
import IconLinkedIn from '@images/resume/icons/linkedin.png';
import IconLocation from '@images/resume/icons/location.png';

const { github, linkedin } = me.social;
const { email, phone } = contact;

export const ContactDetails: React.FC = () => {
  return (
    <footer>
      <address>
        <ContactList>
          {SHOW_CONTACT && [
            <li key='email'>
              <Image src={IconEmail} alt='email' width='18px' />
              <a href={`mailto:${email}`} title='Email via Gmail'>
                {email}
              </a>
            </li>,
            <li key='phone'>
              <Image src={IconPhone} alt='phone' width='18px' />
              <a href={`tel:${phone.href}`} title='India Telephone'>
                {phone.display}
              </a>
            </li>,
          ]}
          <li>
            <Image src={IconLinkedIn} alt='linkedin' width='18px' />
            <a href={linkedin.href} title={linkedin.title}>
              linkedin.com/in/aditimohanty
            </a>
          </li>
          <li>
            <Image src={IconGithub} alt='github' width='18px' />
            <a href={github.href} title={github.title}>
              github.com/rheaditi
            </a>
          </li>
          <li>
            <Image src={IconLocation} alt='location' width='18px' />
            <a href='https://goo.gl/maps/xYtemNQkPb1tXZxP6' title='Work Location'>
              Bengaluru, Karnataka (IST)
            </a>
          </li>
        </ContactList>
      </address>
    </footer>
  );
};
