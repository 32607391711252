import styled from 'styled-components';

export const ContactList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-size: 0.77rem;
  font-weight: 400;

  li {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    flex-wrap: no-wrap;
    text-overflow: ellipsis;
  }

  a,
  a:visited {
    color: #4b73ac;
  }

  a,
  img {
    display: block;
  }

  img {
    width: 18px;
    min-width: 18px;
  }

  a {
    flex: 1;
    transition: color 0.15s ease-out;
    line-height: 1.3;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }

  a:hover {
    color: #2e70cd;
  }

  img {
    margin-right: 16px;
  }
`;
