import React from 'react';
import styled from 'styled-components';

const CONNECTED_COLOR = '#bfd4f1';

export const ItemLabel = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-family: ${(props) => props.theme.fonts.body};
    margin: 0 0 4px 0;
    font-size: 0.89rem;
    line-height: 1;
    font-weight: 600;
  }

  small {
    color: ${(props) => props.theme.colors.muted}};
    font-size: 0.87rem;
    font-weight: 600;
  }

  img {
    width: 34px;
    margin-right: 16px;
  }
`;

export const ItemContent = styled.div`
  font-size: 0.8em;
  margin: 8px 0 0 14px;
  padding: 0 0 6px 34px;
  border-left: 2px solid ${CONNECTED_COLOR};

  p {
    margin-bottom: 1rem;
  }
`;

export const ItemConnector = styled.li`
  min-height: 6px;
  margin: 0 0 0 14px;
  padding: 0 0 0 14px;
  border-left: 2px solid ${CONNECTED_COLOR};
`;

export const ListItem = styled.li`
  margin: 7px 0 0 0;
  padding: 0;

  &:last-of-type ${ItemContent} {
    border-left: none;
  }
`;

const ExperienceItemIndicatorWrapper = styled.div`
  width: 16px;
  padding-right: 4px;
  margin-left: 8px;
  margin-right: 26px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: ${(props) => props.theme.colors.muted};
  svg {
    width: 8px;
    fill: ${CONNECTED_COLOR};
  }
`;

export const Indicator: React.FC = () => (
  <ExperienceItemIndicatorWrapper>
    <svg viewBox='0 0 20 20'>
      <circle cx='10' cy='10' r='10' fill='currentColor' />
    </svg>
  </ExperienceItemIndicatorWrapper>
);

export const List = styled.ul`
  list-style: none;
  margin: 10px 0;
  padding: 0;
`;
