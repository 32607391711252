import styled from 'styled-components';
import { darken } from 'polished';

export const Button = styled.a`
  display: inline-block;
  color: #ffffff;
  background-color: ${(props) => props.theme.colors.primary};
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: ${(props) => props.theme.fonts.body};
  line-height: 20px;
  padding: 14px 24px;
  border-radius: 2px;

  transition: background-color 0.14s ease-out, transform 0.14s ease-in-out;

  &:focus {
    color: #ffffff;
    outline: none;
    background-color: ${(props) => darken(0.1, props.theme.colors.primary)};
  }

  &:hover {
    color: #ffffff;
    cursor: pointer;
    background-color: ${(props) => darken(0.15, props.theme.colors.primary)};
    @media screen and (min-width: ${(props) => props.theme.breakpoints[0]}) {
      transform: translateY(-1px);
    }
  }

  &:active {
    color: #ffffff;
    background-color: ${(props) => darken(0.2, props.theme.colors.primary)};
  }
`;

export const DownloaderWrapper = styled.div`
  @media print {
    display: none;
  }

  @media screen {
    .download-pdf svg {
      vertical-align: top;
      margin-left: 8px;
    }

    .go-back {
      display: none;
    }
  }

  @media screen and (max-width: 39.99em) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 1.1em;
    display: flex;

    hr {
      display: none;
    }

    ${Button} {
      border-radius: 0;
      padding: 20px 30px;
      text-align: center;
    }

    .go-back {
      display: initial;
      border-right: 1px solid white;
      min-width: 0;
      flex: 0 1 auto;
    }

    .download-pdf {
      flex: 1 1 auto;
    }
  }
`;
