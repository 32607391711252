import React from 'react';
import { resume } from '@data/static';
import { Title, Subtitle, HeroHeader } from '@views/resume/styles';

export const Hero: React.FC = () => {
  return (
    <HeroHeader>
      <Title>{resume.basics.name}</Title>
      <Subtitle>{resume.basics.label}</Subtitle>
    </HeroHeader>
  );
};
