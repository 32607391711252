import React from 'react';
import { SectionTitle } from '@views/resume/styles';
import PESITLogo from '@images/resume/pesit-logo-small.png';
import { Image } from 'rebass/styled-components';
import { Timeline, TimelineItem } from '@views/resume/timeline';

export const Education: React.FC = () => {
  return (
    <section>
      <SectionTitle>Education</SectionTitle>
      <Timeline>
        <TimelineItem
          connector={false}
          icon={
            <a href='https://pesitsouth.pes.edu/' title='PESIT, Bangalore South Campus'>
              <Image src={PESITLogo} />
            </a>
          }
          label={[
            <h3 key='title'>
              <a href='https://pesitsouth.pes.edu/' title='PESIT, Bangalore South Campus' className='subtle'>
                PES Institute of Technology, Bangalore South Campus
              </a>
            </h3>,
            <small key='duration'>Aug 2012 - Jul 2016 (4 years) </small>,
          ]}
        >
          <p>
            <strong>Bachelors in Engineering & Computer Science</strong> (78.96% - First Class w/ Distinction)
          </p>
          <p>
            Activities: As <em>Treasurer, PESITSouth ACM Student Chapter</em>, managed sponsorship & organization of
            technical fests and activites on campus.
          </p>
        </TimelineItem>
      </Timeline>
    </section>
  );
};
