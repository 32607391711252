import React from 'react';

import { List, ListItem, ItemLabel, Indicator, ItemContent, ItemConnector } from './styles';

const TimelineItem: React.FC<{
  icon?: React.ReactNode;
  label: React.ReactNode;
  connector?: boolean;
}> = ({ icon, children, label, connector = true }) => {
  return (
    <React.Fragment>
      <ListItem>
        <ItemLabel>
          {icon || <Indicator />}
          <div>{label}</div>
        </ItemLabel>
        <ItemContent>{children}</ItemContent>
      </ListItem>
      {connector && <ItemConnector />}
    </React.Fragment>
  );
};

export { List as Timeline, TimelineItem };
