import React from 'react';
import { Image } from 'rebass/styled-components';
import format from 'date-fns/esm/format';
import formatDistance from 'date-fns/esm/formatDistance';

import { Timeline, TimelineItem } from '@views/resume/timeline';
import { SectionTitle } from '@views/resume/styles';

import ClearTaxLogo from '@images/resume/cleartax-logo-small.png';
import SabreLogo from '@images/resume/sabre-logo-small.png';
import AtlassianLogo from '@images/resume/atlassian-logo-small.png';

const DATE_FORMAT_EXP = `MMM yyyy`;

const EXPERENCE = {
  ATLASSIAN: {
    start: new Date(`2020-09-07`),
  },
  CLEARTAX: {
    SE1: { start: new Date(`2016-12-12`), end: new Date(`2018-10-31`) },
    SE2: { start: new Date(`2018-11-01`), end: new Date(`2019-09-30`) },
    SE3: { start: new Date(`2019-10-01`), end: new Date(`2020-08-28`) },
  },
  SABRE: { start: new Date(`2016-07-04`), end: new Date(`2016-12-11`) },
};

const ExperienceDuration = ({ experience }: { experience: { start: Date; end: Date } }) => {
  return (
    <small>
      {format(experience.start, DATE_FORMAT_EXP)} - {format(experience.end, DATE_FORMAT_EXP)} (
      {formatDistance(experience.start, experience.end)})
    </small>
  );
};

export const WorkExperience: React.FC = () => {
  return (
    <section>
      <SectionTitle>Experience</SectionTitle>
      <Timeline>
        <TimelineItem
          connector={false}
          icon={
            <a href='https://atlassian.com/' title='Atlassian'>
              <Image src={AtlassianLogo} />
            </a>
          }
          label={[
            <h3 key='title'>
              Software Development Engineer II @{' '}
              <a href='https://atlassian.com/' title='Atlassian' className='subtle'>
                Atlassian
              </a>
            </h3>,
            <small key='duration'>
              {format(EXPERENCE.ATLASSIAN.start, DATE_FORMAT_EXP)} - Present{' '}
              <span className='media-hide-print'>({formatDistance(EXPERENCE.ATLASSIAN.start, new Date())})</span>
            </small>,
          ]}
        >
          <p>
            Working as a front-end engineer on Atlassian Marketplace - a distribution platform for apps, addons and
            integrations for the ecosystem of Atlassian products.
          </p>
        </TimelineItem>
      </Timeline>
      <Timeline>
        <TimelineItem
          icon={
            <a href='https://cleartax.in/' title='ClearTax India'>
              <Image src={ClearTaxLogo} />
            </a>
          }
          label={[
            <h3 key='title'>
              Software Engineer III @{' '}
              <a href='https://cleartax.in/' title='ClearTax India' className='subtle'>
                cleartax.in
              </a>
            </h3>,
            <ExperienceDuration experience={EXPERENCE.CLEARTAX.SE3} key='duration' />,
          ]}
        >
          <p>
            Worked as part of the <strong>front-end platform team</strong> architecting & maintaining core services to
            support building of stable & high-quality financial products.
          </p>
          <p>
            Contributed to the B2B <strong>design system,</strong> common services like launchpad, front-end feature
            flag service, single-sign-on, tools to improve <strong>developer experience</strong>, standards for
            code-quality, performance & security practices.
          </p>
        </TimelineItem>
        <TimelineItem
          label={[
            <h3 key='title'>
              Software Engineer II @{' '}
              <a href='https://cleartax.in/' title='ClearTax India' className='subtle'>
                cleartax.in
              </a>
            </h3>,
            <ExperienceDuration experience={EXPERENCE.CLEARTAX.SE2} key='duration' />,
          ]}
        >
          <p>
            Worked as part of a cross-functional team owning the account management, reporting and filing modules of{' '}
            <a href='https://cleartax.in/gst?ref=aditimohanty.com' target='_blank' rel='noreferrer nofollow'>
              ClearTax GST
            </a>{' '}
            (business GST compliance software).
          </p>
        </TimelineItem>
        <TimelineItem
          connector={false}
          label={[
            <h3 key='title'>
              Software Engineer @{' '}
              <a href='https://cleartax.in/?ref=aditimohanty.com' title='ClearTax India' className='subtle'>
                cleartax.in
              </a>
            </h3>,
            <ExperienceDuration experience={EXPERENCE.CLEARTAX.SE1} key='duration' />,
          ]}
        >
          <p>
            Worked as part of the consumer products front-end team, contributing to the ClearTax filing product over 2
            filing seasons, building the content pipeline for authoring & publishing articles on cleartax.in/s/ and
            building several small widgets and personal finance calculators to increase user engagement on articles.
          </p>
        </TimelineItem>
      </Timeline>
      <Timeline id='experience-timeline--sabre'>
        <TimelineItem
          connector={false}
          icon={
            <a href='https://www.sabre.com/' title='Sabre Corporation'>
              <Image src={SabreLogo} />
            </a>
          }
          label={[
            <h3 key='title'>
              Associate Software Developer @{' '}
              <a href='https://www.sabre.com/' title='Sabre Corporation' className='subtle'>
                Sabre
              </a>
            </h3>,
            <ExperienceDuration experience={EXPERENCE.SABRE} key='duration' />,
          ]}
        >
          <p>
            Junior developer & QA for GetThere (Internet-based corporate travel procurement and supply services for
            businesses and travel suppliers) by Sabre.
          </p>
        </TimelineItem>
      </Timeline>
    </section>
  );
};
