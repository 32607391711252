import React from 'react';
import { resume } from '@data/static';
import { SectionTitle, ReferenceList } from '@views/resume/styles';

export const ProfessionalReferences: React.FC<{ className?: string }> = (props) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <section className={props.className}>
      <SectionTitle>References</SectionTitle>
      <ReferenceList>
        {resume.references.map((reference) => (
          <li key={reference.name}>
            <a href={reference.href}>{reference.name}</a> - {reference.reference}
          </li>
        ))}
      </ReferenceList>
    </section>
  );
};
