import React from 'react';
import { Helmet } from 'react-helmet';
import themePreset from '@rebass/preset';
import merge from 'lodash/fp/merge';
import { ThemeProvider } from 'styled-components';

// types
import type { PageProps } from 'gatsby';
import type { DefaultTheme } from 'styled-components';

// components
import { ResumeGlobals, Paper, Section } from '@views/resume/styles';
import SEO from '@components/SEO';
import { ProfessionalReferences } from '@views/resume/ProfessionalReferences';
import { Education } from '@views/resume/Education';
import { WorkExperience } from '@views/resume/Experience';
import { ProfessionalSummary } from '@views/resume/Summary';
import { Hero } from '@views/resume/Hero';
import { ContactDetails } from '@views/resume/contact';
import { Work } from '@views/resume/Work';

// data & media
import { SDAditiMohanty, SDThisSite } from '@data/structured/homepage';
import { Downloader } from '@views/resume/download';

const headLinks: React.DetailedHTMLProps<React.LinkHTMLAttributes<HTMLLinkElement>, HTMLLinkElement>[] = [
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Poppins:wght@800&display=swap',
  },
];

const resumeTheme = merge(themePreset, {
  fonts: {
    body: `'Open Sans', system-ui, sans-serif`,
    heading: `'Poppins', sans-serif;`,
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 600,
  },
  colors: {
    primary: '#275EFE',
    heading: '#21426F',
    debug: '#7B1A53',
    text: '#134487',
    muted: '#74a1e0',
  },
  lineHeights: {
    body: '1.5',
    heading: '1.5',
  },
  buttons: {
    primary: {
      bg: '#275EFE',
    },
  },
  breakpoints: ['40em', '52em', '64em'],
  dark: false,
  light: true,
}) as DefaultTheme;

export const ResumePage: React.FC<PageProps> = (props) => {
  return (
    <ThemeProvider theme={resumeTheme}>
      <SEO title='Resume' pathname={props.location.pathname}>
        <SDAditiMohanty />
        <SDThisSite />
        <Helmet link={headLinks} />
      </SEO>
      <ResumeGlobals />
      <Paper>
        <Section secondary>
          <Hero />
          <ContactDetails />
          <Downloader />
          <hr className='media-hide-screen' />
          <ProfessionalReferences className='media-hide-screen' />
        </Section>
        <Section>
          <ProfessionalSummary />
          <WorkExperience />
          <Work />
          <Education />
          <ProfessionalReferences className='media-hide-print' />
        </Section>
      </Paper>
    </ThemeProvider>
  );
};
