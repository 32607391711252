import React from 'react';
import { SectionTitle } from '@views/resume/styles';
import { Image } from 'rebass/styled-components';
import { Timeline, TimelineItem } from '@views/resume/timeline';
import CasualDBLogo from '@images/resume/casualdb-logo-small.svg';

export const Work: React.FC = () => {
  return (
    <section id='section--work'>
      <SectionTitle>Work</SectionTitle>
      <Timeline>
        <TimelineItem
          connector={false}
          icon={
            <a href='https://boxshadows.com/?utm_source=resume_work_aditi' title='boxshadows.com'>
              <Image src='https://boxshadows.com/assets/favicon-48x48.png' />
            </a>
          }
          label={[
            <h3 key='title'>
              <a href='https://boxshadows.com/?utm_source=resume_work_aditi' title='boxshadows.com' className='subtle'>
                boxshadows.com
              </a>
            </h3>,
            <small key='url'>github.com/campvanilla/boxshadows</small>,
          ]}
        >
          <p>
            A tool to create and edit CSS box-shadows in the browser or quickly copy &amp; tweak aesthetic presets such
            as those from material-ui, tailwind etc.
          </p>
        </TimelineItem>
      </Timeline>
      <Timeline>
        <TimelineItem
          connector={false}
          icon={
            <a href='https://github.com/campvanilla/casualdb' title='casualdb for deno'>
              <Image src={CasualDBLogo} />
            </a>
          }
          label={[
            <h3 key='title'>
              <a href='https://github.com/campvanilla/casualdb' title='casualdb for deno' className='subtle'>
                casualdb
              </a>
            </h3>,
            <small key='url'>https://github.com/campvanilla/casualdb | https://deno.land/x/casualdb</small>,
          ]}
        >
          <p>
            An ultra-simple JSON filesystem based {`'database'`} for deno with a neat ype-safe API. Get started with
            building with casualdb for your prototypes, CLI tools etc.
          </p>
        </TimelineItem>
      </Timeline>
    </section>
  );
};
